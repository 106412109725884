import React from "react"
import FooterContent from "../FooterContent"
import IconsContainer from "../IconsContainer"
import LogoText from "../LogoText"
import "./index.scss"

const TopFooter = () => {
  return (
    <div className="top-footer-holder">
      <LogoText />
      <FooterContent />
      <IconsContainer />
    </div>
  )
}

export default TopFooter
