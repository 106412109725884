import ReactGA, { ga } from "react-ga"

//gtag("event", ACTION, {category: string, label: string})
export const gaInsuranceClickEvent = label => {
  if (process.env.NODE_ENV === "production") {
    try {
      window.gtag("event", "Osiguranja", {
        event_category: "Klikovi",
        event_label: label,
      })
      if (process.env.GA_DEBUG === "true") {
        console.log("GA_EVENT:", {
          action: "Osiguranja",
          category: "Klikovi",
          label: label,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
  return
}

export const gaOfferInteraction = label => {
  if (process.env.NODE_ENV === "production") {
    try {
      window.gtag("event", "Zatrazi Ponudu", {
        event_category: "Uspjesnost",
        event_label: label,
      })
      if (process.env.GA_DEBUG === "true") {
        console.log("GA_EVENT:", {
          action: "Zatrazi Ponudu",
          category: "Uspjesnost",
          label: label,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
  return
}

export const gaCallClickEvent = label => {
  if (process.env.NODE_ENV === "production") {
    try {
      window.gtag("event", "Zatrazi Poziv", {
        event_category: "Klikovi",
        event_label: label,
      })
      if (process.env.GA_DEBUG === "true") {
        console.log("GA_EVENT:", {
          action: "Zatrazi Poziv",
          category: "Klikovi",
          label: label,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
  return
}

export const gaCallInteraction = label => {
  if (process.env.NODE_ENV === "production") {
    try {
      window.gtag("event", "Zatrazi Poziv", {
        event_category: "Uspjesnost",
        event_label: label,
      })
      if (process.env.GA_DEBUG === "true") {
        console.log("GA_EVENT:", {
          action: "Zatrazi Poziv",
          category: "Uspjesnost",
          label: label,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
  return
}

export const gaContactClickEvent = label => {
  if (process.env.NODE_ENV === "production") {
    try {
      window.gtag("event", "Kontakt", {
        event_category: "Klikovi",
        event_label: label,
      })
      if (process.env.GA_DEBUG === "true") {
        console.log("GA_EVENT:", {
          action: "Kontakt",
          category: "Klikovi",
          label: label,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
  return
}

export const gaContactInteraction = label => {
  if (process.env.NODE_ENV === "production") {
    try {
      window.gtag("event", "Kontakt", {
        event_category: "Uspjesnost",
        event_label: label,
      })
      if (process.env.GA_DEBUG === "true") {
        console.log("GA_EVENT:", {
          action: "Kontakt",
          category: "Uspjesnost",
          label: label,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
  return
}

export const gaOfferClickEvent = label => {
  if (process.env.NODE_ENV === "production") {
    try {
      window.gtag("event", "Zatrazi Ponudu", {
        event_category: "Klikovi",
        event_label: label,
      })
      if (process.env.GA_DEBUG === "true") {
        console.log("GA_EVENT:", {
          action: "Zatrazi Ponudu",
          category: "Klikovi",
          label: label,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
  return
}

export const gaBlogClickEvent = label => {
  if (process.env.NODE_ENV === "production") {
    try {
      window.gtag("event", "Blog", {
        event_category: "Klikovi",
        event_label: label,
      })
      if (process.env.GA_DEBUG === "true") {
        console.log("GA_EVENT:", {
          action: "Blog",
          category: "Klikovi",
          label: label,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
  return
}

export const gaOtherClickEvent = label => {
  if (process.env.NODE_ENV === "production") {
    try {
      window.gtag("event", "Ostalo", {
        event_category: "Klikovi",
        event_label: label,
      })
      if (process.env.GA_DEBUG === "true") {
        console.log("GA_EVENT:", {
          action: "Ostalo",
          category: "Klikovi",
          label: label,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
  return
}
