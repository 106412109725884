import React, { useEffect, useRef, useState } from "react"
import { Link } from "gatsby"
import { BackArrowIcon } from "../../../assets/icons"
import DropDownMenu from "../../../browser/components/DropDownMenu"
import Slider from "../../../browserMobile/components/Slider"
import "./index.scss"
import PopUpNavbar from "./pieces"
import Logo from "../Logo"
import { useLocation } from "@reach/router"
import { openModal } from "../../../redux/actions/strapiActions"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  gaBlogClickEvent,
  gaContactClickEvent,
  gaOfferClickEvent,
} from "../../../helpers/functions/googleAnalytics"

const Navbar = ({
  openMobile,
  onClickLinkCloseBurgerBar,
  openModal,
  isOpenModal,
}) => {
  const [isHover, setHover] = useState(false)
  const [windowWidth, setWindowWidth] = useState(0)
  const [counter, setCounter] = useState(0)
  const [stayOpen, setStayOpen] = useState(false)
  const navbarHolder = useRef()
  const whiteBackgrRef = useRef()
  const step1 = useRef()
  const step2 = useRef()

  let forwardRef = navbarHolder.current // problem when passing to second component (undefined)

  const location = useLocation()
  let routeSlug = location.pathname

  const onMouseEnter = () => {
    setHover(true)
  }

  const onMouseLeave = () => {
    setHover(false)
  }

  const handleResize = () => {
    setWindowWidth(window && window.innerWidth)
  }

  const handleClickBack = () => {
    setCounter(0)
  }

  const onClickInsurance = () => {
    setCounter(1)
  }

  const delayAndGo = (e, to) => {
    //e.preventDefault()
    navbarHolder.current.classList.add("fadeOut")
    whiteBackgrRef.current.classList.add("fadeOut")
    setCounter(0)
    setTimeout(() => {
      onClickLinkCloseBurgerBar()
      window.location.href = to
    }, 0)
  }

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)
    return function cleanup() {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    if (openMobile || isOpenModal) {
      document.body.classList.add("body-scroll")
    } else if (!openMobile) {
      document.body.classList.remove("body-scroll")
    } else if (!isOpenModal) {
      document.body.classList.remove("body-scroll")
    }
    setCounter(0)
  }, [openMobile, isOpenModal])

  useEffect(() => {
    if (windowWidth < 1025) {
      navbarHolder.current.classList.remove("fadeIn")
      navbarHolder.current.classList.add("fadeIn")
    }

    if (windowWidth < 1025 && counter === 0) {
      step1.current.classList.remove("fadeIn")
      step1.current.classList.add("fadeIn")
    }

    if (windowWidth < 1025 && counter === 1) {
      step2.current.classList.remove("fadeIn")
      step2.current.classList.add("fadeIn")
    }
  }, [counter, openMobile])

  return (
    <>
      <div
        ref={navbarHolder}
        className={`navbar-holder ${openMobile ? "active" : ""} ${
          counter ? "height" : ""
        }`}
        key={counter}
      >
        {counter === 0 ? (
          <>
            {windowWidth < 1025 && (
              <div className="navbar-logo-close-holder">
                <Logo />{" "}
                <div
                  className="burger-bar"
                  onClick={() => {
                    navbarHolder.current.classList.add("fadeOut")
                    whiteBackgrRef.current.classList.add("fadeOut")
                    setTimeout(() => {
                      onClickLinkCloseBurgerBar()
                    }, 250)
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="line1"></div>
                  <div className="line2"></div>
                </div>
              </div>
            )}

            <div ref={step1} className="step-1-holder">
              <ul className="navbar-list">
                <li
                  className="navbar-item"
                  onMouseEnter={(windowWidth >= 1025 && onMouseEnter) || null}
                  onMouseLeave={windowWidth >= 1025 ? onMouseLeave : null}
                >
                  <span
                    className={`navbar-link ${
                      routeSlug.includes("insurance") && windowWidth < 1025
                        ? "active"
                        : ""
                    }`}
                    onClick={() => windowWidth < 1025 && onClickInsurance()}
                    style={{ cursor: "pointer" }}
                  >
                    Osiguranje
                  </span>
                </li>
                <li className="navbar-item">
                  <Link
                    to="/about-us"
                    className={`navbar-link ${
                      routeSlug.includes("about-us") && windowWidth < 1025
                        ? "active"
                        : ""
                    }`}
                    onClick={e =>
                      windowWidth < 1025 && delayAndGo(e, "/about-us")
                    }
                    style={{ cursor: "pointer" }}
                  >
                    O nama
                  </Link>
                </li>
                <li className="navbar-item">
                  <Link
                    to="/contact"
                    className={`navbar-link ${
                      routeSlug.includes("contact") && windowWidth < 1025
                        ? "active"
                        : ""
                    }`}
                    onClick={e => {
                      gaContactClickEvent("Kontakt stranica otvorena")
                      windowWidth < 1025 && delayAndGo(e, "/contact")
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Kontakt
                  </Link>
                </li>
                <li className="navbar-item">
                  <Link
                    to="/blog"
                    className={`navbar-link ${
                      routeSlug.includes("blog") && windowWidth < 1025
                        ? "active"
                        : ""
                    }`}
                    onClick={e => {
                      gaBlogClickEvent("Klik na blog stranicu sa navigacije")
                      windowWidth < 1025 && delayAndGo(e, "/blog")
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Blog
                  </Link>
                </li>
                <li className="navbar-item-ponuda">
                  <button
                    className="offer-btn"
                    onClick={() => {
                      gaOfferClickEvent("Klik na Zatrazi Ponudu sa navigacije")
                      openModal()
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Zatraži ponudu
                  </button>
                </li>
              </ul>
              {windowWidth < 1025 && (
                <span className="content-separator-border"></span>
              )}
              {windowWidth < 1025 ? <Slider /> : null}
            </div>
          </>
        ) : (
          <>
            {windowWidth < 1025 && (
              <div className="navbar-arrow-close-holder">
                <div
                  className="back-arrow-holder"
                  onClick={handleClickBack}
                  style={{ cursor: "pointer" }}
                >
                  <BackArrowIcon />
                  <h4 className="back-text">Natrag</h4>
                </div>
                <div
                  className="burger-bar"
                  onClick={() => {
                    navbarHolder.current.classList.add("fadeOut")
                    whiteBackgrRef.current.classList.add("fadeOut")
                    setTimeout(() => {
                      onClickLinkCloseBurgerBar()
                    }, 250)
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="line1"></div>
                  <div className="line2"></div>
                </div>
              </div>
            )}
            <div ref={step2} className="step-2-holder">
              <PopUpNavbar
                counter={counter}
                refProp={forwardRef}
                onClickLinkCloseBurgerBar={onClickLinkCloseBurgerBar}
              />
            </div>
          </>
        )}
      </div>
      {openMobile && (
        <div className="white-background-mobile" ref={whiteBackgrRef}></div>
      )}
      <DropDownMenu
        stayOpen={stayOpen}
        setStayOpen={setStayOpen}
        isOpen={isHover}
        onClickLinkCloseBurgerBar={onClickLinkCloseBurgerBar}
      />
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    isOpenModal: state.strapiReducer.isOpenModal,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openModal: bindActionCreators(openModal, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
