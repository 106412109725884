import React from "react"
import "./style.scss"

const Loader = () => {
  return (
    <div className="box">
      <div className="loader-34"></div>
    </div>
  )
}

export default Loader
