import React, { useState, useEffect } from "react"
import { MailIcon, MobileIcon } from "../../../assets/icons/index"
import "./index.scss"

import { connect } from "react-redux"
import { gaOtherClickEvent } from "../../../helpers/functions/googleAnalytics"

const IconsContainer = ({ settings }) => {
  const [windowWidth, setWindowWidth] = useState(0)

  const handleResize = () => {
    setWindowWidth(window && window.innerWidth)
  }

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)
    return function cleanup() {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <>
      {windowWidth >= 1025 ? (
        <div className="icons-holder">
          <MailIcon className="mail-icon" />
          <a
            href={`mailto:${settings?.Email}`}
            className="settings-link"
            onClick={() => gaOtherClickEvent("Klik na email u footeru")}
          >
            <h4>{settings?.Email}</h4>
          </a>
          <MobileIcon className="mobile-icon" />
          {settings?.Svi_Kontakti?.map(contact => (
            <a
              key={contact?.id}
              href={`tel:${contact?.Kontakt?.slice(0, 12)}`}
              className="settings-link"
              style={{ cursor: "pointer" }}
              onClick={() =>
                gaOtherClickEvent("Klik na kontakt broj u footeru")
              }
            >
              <h4>{contact?.Kontakt}</h4>
            </a>
          ))}
        </div>
      ) : (
        <div className="icons-holder">
          <div className="icons-container">
            <div className="text-icon-holder">
              <MailIcon className="mail-icon" style={{ cursor: "pointer" }} />
              <a
                href={`mailto:${settings?.Email}`}
                className="settings-link"
                style={{ cursor: "pointer" }}
                onClick={() => gaOtherClickEvent("Klik na email u footeru")}
              >
                <h4>{settings?.Email}</h4>
              </a>
            </div>
            <div className="text-icon-holder">
              <MobileIcon
                className="mobile-icon"
                style={{ cursor: "pointer" }}
              />
              <div className="text-holder">
                {settings?.Svi_Kontakti?.map(contact => (
                  <a
                    key={contact?.id}
                    href={`tel:${contact?.Kontakt?.slice(0, 12)}`}
                    className="settings-link"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      gaOtherClickEvent("Klik na kontakt broj u footeru")
                    }
                  >
                    <h4 style={{ marginBottom: "10px" }}>{contact?.Kontakt}</h4>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    settings: state.strapiReducer.settings,
  }
}

export default connect(mapStateToProps, null)(IconsContainer)
