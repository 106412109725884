import React, { useEffect, useRef, useState } from "react"
import {
  CarIcon,
  HouseIcon,
  HealthIcon,
  HeartIcon,
  BoatIcon,
} from "../../../assets/icons"
import strapi from "../../../api/strapi"
import "./index.scss"

import { connect } from "react-redux"
import { gaOfferInteraction } from "../../../helpers/functions/googleAnalytics"
import axios from "axios"

const OfferContent2 = ({ stepForward, offerDatas }) => {
  const [isIconActive, setIconActive] = useState(0)
  const [isIconHover, setIconHover] = useState(false)
  const offerTypeRef = useRef(false)

  // const handleOnHover = (i) => {
  //   setIconHover(!isIconHover)
  // }

  const onSubmit = async e => {
    e.preventDefault()
    const responsePonuda = await strapi.post("/ponudas", {
      Ime_Prezime: offerDatas.names,
      Email: offerDatas.email,
      Broj_Mobitela: offerDatas.call_mobile,
      Tip_Osiguranja:
        isIconActive === 1
          ? "automobil"
          : isIconActive === 2
          ? "imovina"
          : isIconActive === 3
          ? "zdravstveno"
          : isIconActive === 4
          ? "zivotno"
          : "ostalo",
    })

    if (responsePonuda.status === 200) {
      try {
        const resFuelMail = await axios.post(
          "https://mail.trebamosiguranje.hr/dalmatiko-mailer/v1/send",
          {
            type: "[Ponuda]",
            name: offerDatas.names,
            mobile: offerDatas.call_mobile,
            email: offerDatas.email,
            content: `Tip Osiguranja: ${
              isIconActive === 1
                ? "automobil"
                : isIconActive === 2
                ? "imovina"
                : isIconActive === 3
                ? "zdravstveno"
                : isIconActive === 4
                ? "zivotno"
                : "ostalo"
            }`,
          }
        )
        console.log("Ponuda: ", resFuelMail)
      } catch (err) {
        console.error("Fuel Mailer Error: ", err.message)
      }
    }

    offerTypeRef.current = false
    stepForward()
    gaOfferInteraction("Ponuda zatrazena")
  }

  const gaInteraction = () => {
    if (!offerTypeRef.current) {
      gaOfferInteraction("Korisnik odabrao tip osiguranja")
      offerTypeRef.current = true
    }
  }

  return (
    <div className="offer-content-2">
      <h3 className="offer-title">Odaberi tip osiguranja</h3>
      <div
        className="offer-types-container"
        onKeyDown={e => e.key === "Enter" && onSubmit(e)}
        tabIndex="0"
      >
        <div className="offer-icon-holder">
          <div
            id="car"
            className={`offer-icon ${isIconActive === 1 ? "active" : ""}`}
            onMouseEnter={() => setIconHover(1)}
            onMouseLeave={() => setIconHover(0)}
            onClick={() => {
              setIconActive(1)
              gaInteraction()
            }}
          >
            <CarIcon
              isActive={isIconActive === 1 ? true : false}
              isHover={isIconHover === 1 ? true : false}
              className="insurance-icon"
            />
          </div>
          <p
            className={`offer-icon-desc ${
              isIconActive === 1 || isIconHover === 1 ? "active" : ""
            }`}
          >
            Automobil
          </p>
        </div>
        <div className="offer-icon-holder">
          <div
            id="house"
            className={`offer-icon ${isIconActive === 2 ? "active" : ""}`}
            onMouseEnter={() => setIconHover(2)}
            onMouseLeave={() => setIconHover(0)}
            onClick={() => {
              setIconActive(2)
              gaInteraction()
            }}
          >
            <HouseIcon
              isActive={isIconActive === 2 ? true : false}
              isHover={isIconHover === 2 ? true : false}
              className="insurance-icon"
            />
          </div>
          <p
            className={`offer-icon-desc ${
              isIconActive === 2 || isIconHover === 2 ? "active" : ""
            }`}
          >
            Imovina
          </p>
        </div>
        <div className="offer-icon-holder">
          <div
            id="health"
            className={`offer-icon ${isIconActive === 3 ? "active" : ""}`}
            onMouseEnter={() => setIconHover(3)}
            onMouseLeave={() => setIconHover(0)}
            onClick={() => {
              setIconActive(3)
              gaInteraction()
            }}
          >
            <HealthIcon
              isActive={isIconActive === 3 ? true : false}
              isHover={isIconHover === 3 ? true : false}
              className="insurance-icon"
            />
          </div>
          <p
            className={`offer-icon-desc ${
              isIconActive === 3 || isIconHover === 3 ? "active" : ""
            }`}
          >
            Zdravstveno
          </p>
        </div>
        <div className="offer-icon-holder">
          <div
            id="heart"
            className={`offer-icon ${isIconActive === 4 ? "active" : ""}`}
            onMouseEnter={() => setIconHover(4)}
            onMouseLeave={() => setIconHover(0)}
            onClick={() => {
              setIconActive(4)
              gaInteraction()
            }}
          >
            <HeartIcon
              isActive={isIconActive === 4 ? true : false}
              isHover={isIconHover === 4 ? true : false}
              className="insurance-icon"
            />
          </div>
          <p
            className={`offer-icon-desc ${
              isIconActive === 4 || isIconHover === 4 ? "active" : ""
            }`}
          >
            Životno
          </p>
        </div>
        <div className="offer-icon-holder">
          <div
            id="boat"
            className={`offer-icon ${isIconActive === 5 ? "active" : ""}`}
            onMouseEnter={() => setIconHover(5)}
            onMouseLeave={() => setIconHover(0)}
            onClick={() => {
              setIconActive(5)
              gaInteraction()
            }}
          >
            <BoatIcon
              isActive={isIconActive === 5 ? true : false}
              isHover={isIconHover === 5 ? true : false}
              className="insurance-icon"
            />
          </div>
          <p
            className={`offer-icon-desc ${
              isIconActive === 5 || isIconHover === 5 ? "active" : ""
            }`}
          >
            Ostalo
          </p>
        </div>
      </div>
      <p className="offer-desc">
      Klikom na “Zatraži ponudu“ potvrđuješ da si pročitao/la uvjete korištenja i informacije o zaštiti osobnih podataka. Bez brige, čuvamo tvoju privatnost i podatke.
      </p>
      <div className="offer-btn-holder">
        <button
          // type="submit"
          className="offer-btn"
          disabled={isIconActive === 0}
          onClick={e => onSubmit(e)}
        >
          Zatraži ponudu
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    offerDatas: state.strapiReducer.offerDatas,
  }
}

export default connect(mapStateToProps, null)(OfferContent2)
