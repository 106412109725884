import React, { useEffect } from "react"
import strapi from "../../../api/strapi"
import BottomFooter from "../BottomFooter"
import TopFooter from "../TopFooter"
import "./index.scss"

import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import {
  getPagesCategories,
  getProducts,
  getSettings,
} from "../../../redux/actions/strapiActions"

const Footer = ({ getPagesCategories, getSettings, getProducts }) => {
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const responseCategoryPages = await strapi.get("/kategorije-stranicas")

        const responseSettings = await strapi.get("/postavke")

        const responseProducts = await strapi.get("/proizvods")

        getPagesCategories(responseCategoryPages.data)
        getSettings(responseSettings.data)
        getProducts(responseProducts.data)
      } catch (err) {
        console.log(err.message)
      }
    }
    fetchCategories()
  }, [])

  return (
    <footer>
      <TopFooter />
      <BottomFooter />
    </footer>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    getPagesCategories: bindActionCreators(getPagesCategories, dispatch),
    getSettings: bindActionCreators(getSettings, dispatch),
    getProducts: bindActionCreators(getProducts, dispatch),
  }
}

export default connect(null, mapDispatchToProps)(Footer)
