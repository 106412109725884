import React, { useEffect } from "react"
import "./index.scss"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { closeModal, resetData } from "../../../redux/actions/strapiActions"
import success from "../../../assets/success.png"

const OfferContent3 = ({ closeModal, resetData }) => {
  useEffect(() => {
    resetData()
  }, [])

  setTimeout(() => {
    closeModal()
  }, 3000)

  const onSubmit = () => {
    closeModal()
  }
  return (
    <div className="offer-content-3">
      <div className="offer-img-holder">
        <img src={success} alt="Success Img" className="offer-img" />
      </div>
      <h3 className="offer-title">Super, ponuda je zatražena</h3>
      <p className="offer-desc">
        Javit cemo se u potvrđuješ da si pročitao/la Informaciju o zaštiti
        osobnih podataka te da si upoznat/a s njihovom pohranom.
      </p>
      <div className="offer-btn-holder">
        <button className="offer-btn" type="submit" onClick={() => onSubmit()}>
          Super
        </button>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    resetData: bindActionCreators(resetData, dispatch),
    closeModal: bindActionCreators(closeModal, dispatch),
  }
}

export default connect(null, mapDispatchToProps)(OfferContent3)
