import React, { useState } from "react"
import Navbar from "../../../universal/components/Navbar"
import "./index.scss"

const Burgerbar = () => {
  const [isActive, setIsActive] = useState(false)

  const onClickBurgerBar = () => {
    setIsActive(true)
  }

  return (
    <>
      <div className="burger-bar" onClick={() => onClickBurgerBar()}>
        <div className="line1"></div>
        <div className="line2"></div>
      </div>
      <Navbar
        openMobile={isActive}
        onClickLinkCloseBurgerBar={() => setIsActive(false)}
      />
    </>
  )
}

export default Burgerbar
