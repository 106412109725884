import React, { useState, useEffect } from "react"
import Burgerbar from "../../../browserMobile/components/Burgerbar"
import Logo from "../../../universal/components/Logo"
import Navbar from "../../../universal/components/Navbar"
import { connect } from "react-redux"

import "./index.scss"
import Offer from "../../../universal/components/Offer"
import SuccessPopup from "../../../universal/components/SuccessPopup"
import WhiteBackground from "../../../browserMobile/components/WhiteBackground"
import Loader from "../../../universal/components/Loader"

const Header = ({ isOpenModal, isOpenModalSentMessage }) => {
  const [windowWidth, setWindowWidth] = useState(0)

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)
    return function cleanup() {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <>
      <header>
        <Logo />
        {windowWidth < 1025 ? <Burgerbar /> : <Navbar />}
      </header>
      {isOpenModal && <Offer />}
      {isOpenModalSentMessage && <SuccessPopup type = {isOpenModalSentMessage}/>}
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    isOpenModal: state.strapiReducer.isOpenModal,
    isOpenModalSentMessage: state.strapiReducer.isOpenModalSentMessage,
  }
}

export default connect(mapStateToProps, null)(Header)
