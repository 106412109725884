import React from "react"
import CardsContainer from "../../../../browser/components/CardsContainer"
import InsurancesContainer from "../../../../browser/components/InsurancesContainer"
import "./index.scss"

const PopUpNavbar = ({ counter, refProp, onClickLinkCloseBurgerBar }) => {
  return (
    <>
      <h3 className="pop-up-title">Popularna osiguranja</h3>
      <CardsContainer popUpCounter={counter} refProp={refProp} onClickLinkCloseBurgerBar = {onClickLinkCloseBurgerBar}/>
      <span className="content-separator-border"></span>
      <InsurancesContainer popUpCounter={counter} refProp={refProp} onClickLinkCloseBurgerBar = {onClickLinkCloseBurgerBar} />
    </>
  )
}

export default PopUpNavbar
