import {
  CLOSE_MODAL,
  CLOSE_WHITE_BACKGROUND,
  GET_ABOUT_US,
  GET_BLOGS,
  GET_CONCLUSION_DATA,
  GET_LANDING_DATA,
  GET_ONE_BLOG,
  GET_ONE_INSURANCE,
  GET_PAGES_CATEGORIES,
  GET_PRODUCTS,
  GET_PRODUCT_CATEGORIES,
  GET_SETTINGS,
  LAST_DELAY_TIME,
  OPEN_MODAL,
  OPEN_MODAL_SENT_MESSAGE,
  OPEN_WHITE_BACKGROUND,
  RESET_DATA,
  SAVE_OFFER_DATAS,
} from "./actionTypes"

export const getPagesCategories = categories => {
  // console.log("akcija kategorije: ", categories)
  return {
    type: GET_PAGES_CATEGORIES,
    payload: categories,
  }
}

export const getSettings = settings => {
  return {
    type: GET_SETTINGS,
    payload: settings,
  }
}

export const getProducts = products => {
  return {
    type: GET_PRODUCTS,
    payload: products,
  }
}

export const getBlogs = blogs => {
  return {
    type: GET_BLOGS,
    payload: blogs,
  }
}

export const getOneBlog = id => {
  return {
    type: GET_ONE_BLOG,
    payload: id,
  }
}

export const getOneInsurance = id => {
  return {
    type: GET_ONE_INSURANCE,
    payload: id,
  }
}

export const getAboutUs = aboutUs => {
  return {
    type: GET_ABOUT_US,
    payload: aboutUs,
  }
}

export const saveOfferDatas = datas => {
  return {
    type: SAVE_OFFER_DATAS,
    payload: datas,
  }
}

export const resetData = () => {
  return {
    type: RESET_DATA,
  }
}

export const getProductCategories = categories => {
  return {
    type: GET_PRODUCT_CATEGORIES,
    payload: categories,
  }
}

export const getConclusionData = data => {
  return {
    type: GET_CONCLUSION_DATA,
    payload: data,
  }
}

export const getLandingData = data => {
  return {
    type: GET_LANDING_DATA,
    payload: data,
  }
}

export const openModal = () => {
  return {
    type: OPEN_MODAL,
  }
}

export const openModalSentMessage = (type) => {
  return { type: OPEN_MODAL_SENT_MESSAGE, data: type }
}

export const closeModal = () => {
  return {
    type: CLOSE_MODAL,
  }
}

export const setLastDelayTime = time => {
  return {
    type: LAST_DELAY_TIME,
    payload: time,
  }
}
