import React from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import "./index.scss"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import {
  setStaticLoader,
  unsetStaticLoader,
} from "../../../redux/actions/strapiActions"
import { gaInsuranceClickEvent, gaOtherClickEvent } from "../../../helpers/functions/googleAnalytics"

const FooterContent = ({ categoriesList, productsList }) => {
  const location = useLocation()
  let routeSlug = location.pathname.split("/").pop()

  return (
    <>
      <div className="footer-content-holder">
        <div className="content-item">
          <h3 className="content-item-title">Osiguranje</h3>
          <ul className="item-holder">
            {productsList.map(product => (
              <li className="item" key={product.id}>
                <Link
                  to={`/insurance/${product.Slug}`}
                  className={`content-link ${
                    routeSlug === product.Slug ? "active" : ""
                  }`}
                  onClick = {() => gaInsuranceClickEvent(`Klik na osiguranje (${product?.Slug}) sa footera`)}
                >
                  {product.Ime}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        {categoriesList.map(category => (
          <div
            className="content-item"
            key={Math.random().toString(36).substr(2, 9)}
          >
            <h3 className="content-item-title">{category.Ime}</h3>
            <ul className="item-holder">
              {category.Stranice.map(page => (
                <li
                  className="item"
                  key={Math.random().toString(36).substr(2, 9)}
                >
                  <Link
                    to={`/article/${page.Slug}`}
                    className={`content-link ${
                      routeSlug === page.Slug ? "active" : "null"
                    }`}
                    onClick = {() => gaOtherClickEvent(`Klik na stranicu (${page.Slug}) sa footer pozicije A`)}
                  >
                    {page.Title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    categoriesList: state.strapiReducer.categoriesList,
    productsList: state.strapiReducer.productsList,
  }
}

export default connect(mapStateToProps, null)(FooterContent)
