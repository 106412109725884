import React from "react"
import Collab from "../Collab"
import Credits from "../Credits"
import "./index.scss"

const BottomFooter = () => {
  return (
    <div className="bottom-footer-holder">
      <Collab />
      <Credits />
    </div>
  )
}

export default BottomFooter
