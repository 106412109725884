import React from "react"
import dalmatikoLogo from "../../../assets/dalmatikoLogo.png"
import { Link } from "gatsby"
import { connect } from "react-redux"
import "./index.scss"

const Logo = ({ settings }) => {
  return (
    <Link to="/">
      {" "}
      <img
        src={`https://api.trebamosiguranje.hr${settings?.Logo?.url}`}
        alt="Dalmatiko Logo"
        className="logo"
        style={{ cursor: "pointer" }}
        onLoad={e => e.target.classList.add("loaded")}
        onError={e => {
          e.target.src = dalmatikoLogo
          e.target.classList.add("loaded")
        }}
      />
    </Link>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    settings: state.strapiReducer.settings,
  }
}

export default connect(mapStateToProps, null)(Logo)
