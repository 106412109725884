import "./index.scss"

export const formatDate = date => {
  let newDate = new Date(date)

  return `${newDate.getDate()}.${
    newDate.getMonth() + 1
  }.${newDate.getFullYear()}.`
}

export const convertMdLink = link => {
  const linkRegex = /^\[([\w\s\d]+)\]\(((?:\/|https?:\/\/)[\w\d./?=#]+)\)$/
  const matching = link.match(linkRegex)

  const [full, text, url] = matching

  return `<a href=${url}>${text}</a>`
}

export const showCookieBanner = () => !document.cookie.match(
  /^(.*;)?\s*gdpr-analytics-banner-disable=true\s*=\s*[^;]+(.*)?$/) && window.location.pathname !== '/article/kolacici' && !document.cookie.match(
  /^(.*;)?\s*gdpr-analytics-enabled\s*=\s*[^;]+(.*)?$/
);