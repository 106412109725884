import React, { useEffect, useState } from "react"

import CardItem from "../CardItem"
import "./index.scss"

const CardsContainer = ({
  popUpCounter,
  onClickLinkCloseBurgerBar,
  refProp,
}) => {
  const [windowWidth, setWindowWidth] = useState(0)

  const handleResize = () => {
    setWindowWidth(window && window.innerWidth)
  }

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)
    return function cleanup() {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <>
      {windowWidth < 1025 ? (
        <div
          className={`card-holder-helper ${
            popUpCounter === 1 ? "active-popup" : ""
          }`}
        >
          <div
            className={`card-holder ${
              popUpCounter === 1 ? "active-popup" : ""
            }`}
          >
            <CardItem onClickLinkCloseBurgerBar={onClickLinkCloseBurgerBar} />
          </div>
        </div>
      ) : (
        <div
          className={`card-holder ${popUpCounter === 1 ? "active-popup" : ""}`}
        >
          <CardItem onClickLinkCloseBurgerBar={onClickLinkCloseBurgerBar} />
        </div>
      )}
    </>
  )
}

export default CardsContainer
