import React from "react"
import { Link } from "gatsby"
import "./index.scss"
import { connect } from "react-redux"
import { gaInsuranceClickEvent } from "../../../helpers/functions/googleAnalytics"

const InsurancesContainer = ({
  popUpCounter,
  secondaryProductCategory,
  onClickLinkCloseBurgerBar,
  refProp,
}) => {
  const delayAndGo = (e, to) => {
    e.preventDefault()
    // onClickLinkCloseBurgerBar() // not necessary because it will be redirected anywaz

    document.getElementsByClassName("navbar-holder")[0].classList.add("fadeOut")
    setTimeout(() => {
      window.location.href = to
    }, 0)
  }

  return (
    <div className={`insurance-holder ${popUpCounter === 1 ? "active" : ""}`}>
      {secondaryProductCategory?.map(secondary => (
        <div key={secondary?.id} className="content-holder">
          <h4>{secondary?.Ime}</h4>
          {secondary?.Proizvodi?.map(product => (
            <Link
              key={product?.id}
              to={`/insurance/${product?.Slug}`}
              onClick={e => {
                gaInsuranceClickEvent(`Klik na osiguranje (${product?.Slug}) sa navigacije`)
                delayAndGo(e, `/insurance/${product?.Slug}`)
              }}
              style={{ cursor: "pointer" }}
            >
              {product?.Ime}
            </Link>
          ))}
        </div>
      ))}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    secondaryProductCategory: state.strapiReducer.secondaryProductCategory,
  }
}

export default connect(mapStateToProps, null)(InsurancesContainer)
