import React from "react"
import cookies from "../../../assets/cookies.png"
import { XIconCookie } from "../../../assets/icons"
import "./index.scss"

const Cookies = () => {

  const [cookieActive, setCookieActive] = React.useState(true);

  const consentCookies = () => {
    const expireDate = new Date();
    expireDate.setFullYear(expireDate.getFullYear() + 1)
    document.cookie = `gdpr-analytics-enabled=true; expires=${expireDate.toUTCString()}`;
    setCookieActive(false);
    setTimeout(() => window.location.reload(), 2500);
  }

  const closeCookie = () => {
    document.cookie = 'gdpr-analytics-enabled=false';
    document.cookie = 'gdpr-analytics-banner-disable=true'
    setCookieActive(false)
  }

  if(!cookieActive){
    return null
  }

  return (
    <div className="modal-cookies-holder">
      <div className="cookies-container">
        <div className="close-holder">
          <div className="close-holder-hover" onClick={closeCookie}>
            <XIconCookie />
          </div>
        </div>
        <div className="cookie-holder">
          <img src={cookies} alt="Cookies" />
          <h2>Da, imamo kolačića!</h2>
          <p>
            Koristimo kolačiće kako bi poboljšali Vaše korisničko iskustvo i funkcionalnost stranice. Više o tome pročitajte na stranici <a href = "https://dalmatiko-osiguranje.hr/article/kolacici">Kolačići</a>.
          </p>
          <button onClick = {consentCookies}>Prihvaćam</button>
        </div>
      </div>
    </div>
  )
}

export default Cookies
