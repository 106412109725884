import React from "react"
import { Link } from "gatsby"
import "./index.scss"
import { useLocation } from "@reach/router"
import { connect } from "react-redux"
import { gaOtherClickEvent } from "../../../helpers/functions/googleAnalytics"

const Credits = ({ ostaloList }) => {
  const location = useLocation()
  let routeSlug = location.pathname.split("/").pop()

  return (
    <ul className="credits-holder">
      {ostaloList[0]?.Stranice.map(page => (
        <li className="item" key={page.id}>
          <Link
            to={`/article/${page.Slug}`}
            className={`credit-link ${routeSlug === page.Slug ? "active" : ""}`}
            onClick = {() => gaOtherClickEvent(`Klik na stranicu (${page.Slug}) sa footer pozicije B`)}
          >
            {page.Title}
          </Link>
        </li>
      ))}
    </ul>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    ostaloList: state.strapiReducer.ostaloList,
  }
}

export default connect(mapStateToProps, null)(Credits)
