import React, { useEffect, useState } from "react"
import { XIconCookie, BackArrowIcon } from "../../../assets/icons"
import { closeModal, resetData } from "../../../redux/actions/strapiActions"
import OfferContent1 from "../OfferContent1"
import OfferContent2 from "../OfferContent2"
import OfferContent3 from "../OfferContent3"
import "./index.scss"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { gaOfferInteraction } from "../../../helpers/functions/googleAnalytics"

const Offer = ({ isOpen, resetData, closeModal, openModal }) => {
  const [steps, setStep] = useState(0)

  useEffect(() => {
    if (openModal) {
      document.body.classList.add("body-scroll")
    }
  }, [openModal])

  const gaInteraction = () => {
    gaOfferInteraction("Korisnik odustao")
  }
  // if (!isOpen) return null
  return (
    <div className="modal-offer-holder" id="offer">
      <div className="offer-container">
        <div className="close-holder">
          {steps === 1 ? (
            <div className="back-arrow" onClick={() => setStep(steps - 1)}>
              <BackArrowIcon />
            </div>
          ) : null}
          <div
            className="close-holder-hover"
            onClick={() => {
              document.getElementById("offer").classList.add("fadeOut")
              gaInteraction()
              setTimeout(() => {
                // onClose()
                closeModal()
                resetData()
              }, 250)
            }}
          >
            <XIconCookie />
          </div>
        </div>
        {steps === 0 ? (
          <OfferContent1 stepForward={() => setStep(steps + 1)} />
        ) : steps === 1 ? (
          <OfferContent2 stepForward={() => setStep(steps + 1)} />
        ) : (
          <OfferContent3 />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    openModal: state.strapiReducer.openModal,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    resetData: bindActionCreators(resetData, dispatch),
    closeModal: bindActionCreators(closeModal, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Offer)
