import React, { useEffect } from "react"
import { XIconCookie } from "../../../assets/icons"
import "./index.scss"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { closeModal } from "../../../redux/actions/strapiActions"
import PhoneImage from "../../../images/phone.png"

const SuccessPopup = ({ closeModal, isOpenModalSentMessage, type }) => {

  setTimeout(() => {
    closeModal()
  }, 3000)

  useEffect(() => {
    if (isOpenModalSentMessage) {
      document.body.classList.add("body-scroll")
    }
  }, [isOpenModalSentMessage])

  return (
    <div className="modal-success-popup-holder">
      <div className="success-popup-container">
        <div className="close-holder">
          <div className="close-holder-hover" onClick={() => closeModal()}>
            <XIconCookie />
          </div>
        </div>
        <div className="success-popup-content">
          <div className="success-popup-img">
            <img src = {PhoneImage}/>
          </div>
          <h3 className="success-popup-title">
          {type === "inquiry"? "Zaprimili smo tvoj upit." : "Super, poziv je zatražen."}
          </h3>
          <p className="success-popup-desc">
            {type === "inquiry"? "Uskoro ćemo te kontaktirati sa svim potrebnim informacijama." : "Hvala na zatraženom pozivu. Uskoro ćemo te kontaktirati, željno očekujemo tvoja pitanja."}
          </p>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    isOpenModalSentMessage: state.strapiReducer.isOpenModalSentMessage,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModal: bindActionCreators(closeModal, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SuccessPopup)
