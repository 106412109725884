import React from "react"
import CardsContainer from "../../../browser/components/CardsContainer"
import "./index.scss"

const Slider = () => {
  return (
    <div className="slider-container">
      <h3>Popularna osiguranja</h3>

      <CardsContainer />
    </div>
  )
}

export default Slider
