import React, { useEffect, useState } from "react"
import CardsContainer from "../CardsContainer"
import InsurancesContainer from "../InsurancesContainer"
import "./index.scss"

const DropDownMenu = ({
  isOpen,
  onClickLinkCloseBurgerBar,
  setStayOpen,
  stayOpen,
}) => {
  const invisibleDropdownRef = React.createRef()
  const visibleDropdownRef = React.createRef()

  const onMouseEnter = () => {
    setStayOpen(true)
  }

  const onMouseLeave = () => {
    setStayOpen(false)
  }

  React.useEffect(() => {
    if (isOpen || stayOpen) {
      visibleDropdownRef.current.classList.add("active")
      setTimeout(() => invisibleDropdownRef.current?.classList.add("active"), 0)
    } else {
      visibleDropdownRef.current.classList.remove("active")
      setTimeout(
        () => invisibleDropdownRef.current?.classList.remove("active"),
        250
      )
    }
  }, [isOpen, stayOpen])

  return (
    <div
      ref={invisibleDropdownRef}
      className={`dropdown-menu-invisible`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        ref={visibleDropdownRef}
        className={`dropdown-menu-holder`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <CardsContainer onClickLinkCloseBurgerBar={onClickLinkCloseBurgerBar} />
        <InsurancesContainer
          onClickLinkCloseBurgerBar={onClickLinkCloseBurgerBar}
        />
      </div>
    </div>
  )
}

export default DropDownMenu
