import React from "react"
import { Link } from "gatsby"
import dalmatikoLogo from "../../../assets/dalmatikoLogo.png"
import "./index.scss"

import { connect } from "react-redux"

const LogoText = ({ settings }) => {
  return (
    <div className="logo-text-holder">
      {!settings?.Logo || settings?.length === 0 ? (
        <img
          src={dalmatikoLogo}
          alt="Logo"
          style={{ width: "auto", height: "35px" }}
          onLoad={e => e.target.classList.add("loaded")}
        />
      ) : (
        <Link to="/">
          <img
            src={`https://api.trebamosiguranje.hr${settings?.Logo?.url}`}
            alt="Logo"
            style={{ width: "auto", height: "35px" }}
            onLoad={e => e.target.classList.add("loaded")}
          />
        </Link>
      )}
      <p className="footer-paragraph">{settings.Footer_Opis}</p>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    settings: state.strapiReducer.settings,
  }
}

export default connect(mapStateToProps, null)(LogoText)
