import React, { useRef, useState } from "react"
import { useForm } from "react-hook-form"
import "./index.scss"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { saveOfferDatas } from "../../../redux/actions/strapiActions"
import { gaOfferInteraction } from "../../../helpers/functions/googleAnalytics"

const OfferContent1 = ({ stepForward, saveOfferDatas, offerDatas }) => {
  const [isLabelActive, setLabelActive] = useState(0)
  const isItSent = useRef({
    names: false,
    email: false,
    phone: false,
  })

  const { register, handleSubmit, watch, errors } = useForm()

  let values = watch()

  const onSubmit = data => {
    saveOfferDatas(data)
    stepForward()

    switch (isLabelActive) {
      case 1:
        gaOfferInteraction("Korisnik unio ime prezime")

        break

      case 2:
        gaOfferInteraction("Korisnik unio email")
        break

      // case 3:
      //   gaOfferInteraction("Korisnik unio broj mobitela")

      //   break

      default:
        break
    }

    if (Object.keys(errors).length === 0) {
      gaOfferInteraction("Korisnik na drugoj stranici")
    }
  }

  const validateTelNumber = evt => {
    var theEvent = evt || window.event
    let key
    let keyCode

    if (theEvent.type === "paste") {
      key = evt.clipboardData.getData("text/plain")
    } else {
      key = theEvent.keyCode || theEvent.which

      keyCode = theEvent.keyCode || theEvent.which

      key = String.fromCharCode(key)
    }
    let regex = /([0-9]|[\-+/#])+$/i

    if (keyCode === 13) {
      evt.target.blur()
      handleSubmit(onSubmit)
    } else if (!regex.test(key)) {
      theEvent.returnValue = false
      if (theEvent.preventDefault) theEvent.preventDefault()
    }
  }

  const gaInteraction = () => {
    if (values?.names.length > 2 && !isItSent.current.names) {
      gaOfferInteraction("Korisnik unio ime prezime")
      isItSent.current.names = true
    }

    if (
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values?.email) &&
      !isItSent.current.email
    ) {
      gaOfferInteraction("Korisnik unio email")
      isItSent.current.email = true
    }

    if (
      /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/i.test(
        values?.call_mobile
      ) &&
      !isItSent.current.phone
    ) {
      gaOfferInteraction("Korisnik unio broj mobitela")
      isItSent.current.phone = true
    }
  }

  return (
    <>
      <div className="offer-content-1">
        <h3 className="offer-title">Zatraži ponudu</h3>
        <p className="offer-desc">
          Zatrazi ponudu u potvrđuješ da si pročitao/la Informaciju o zaštiti
          osobnih podataka te da si upoznat/a s njihovom pohranom.
        </p>
        <form className="offer-form-1" onSubmit={handleSubmit(onSubmit)}>
          <div className="offer-input-holder">
            <label
              htmlFor="names"
              className={`name-label ${
                isLabelActive === 1 ||
                values.names?.length > 0 ||
                offerDatas.names.length !== 0
                  ? "active"
                  : ""
              } ${errors.names ? "error" : ""}`}
              onClick={e => {
                e.stopPropagation()
                setLabelActive(1)
              }}
            >
              Ime i prezime
            </label>
            <input
              ref={register({
                required: {
                  value: true,
                  message: "This field is required",
                },
                minLength: {
                  value: 2,
                  message: "Min name lenght is 2",
                },
              })}
              id="names"
              name="names"
              type="text"
              defaultValue={offerDatas.names}
              className={`contact-name ${isLabelActive === 1 ? "active" : ""} ${
                errors.names ? "error" : ""
              }`}
              onClick={e => {
                e.stopPropagation()
                setLabelActive(1)
              }}
              onFocus={() => setLabelActive(1)}
              onBlur={() => {
                setLabelActive(0)
                gaInteraction()
              }}
            />
            <label
              htmlFor="email"
              className={`email-label ${
                isLabelActive === 2 ||
                values.email?.length > 0 ||
                offerDatas.email.length !== 0
                  ? "active"
                  : ""
              } ${errors.email ? "error" : ""}`}
              onClick={e => {
                e.stopPropagation()
                setLabelActive(2)
              }}
            >
              Email
            </label>
            <input
              ref={register({
                required: {
                  value: true,
                  message: "This field is required",
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              })}
              id="email"
              name="email"
              type="text"
              defaultValue={offerDatas.email}
              className={`contact-email ${
                isLabelActive === 2 ? "active" : ""
              } ${errors.email ? "error" : ""}`}
              onClick={e => {
                e.stopPropagation()
                setLabelActive(2)
              }}
              onFocus={() => setLabelActive(2)}
              onBlur={() => {
                setLabelActive(0)
                gaInteraction()
              }}
            />
            <label
              htmlFor="call_mobile"
              className={`mobile-label ${
                isLabelActive === 3 ||
                values.call_mobile?.length > 0 ||
                offerDatas.call_mobile.length !== 0
                  ? "active"
                  : ""
              } ${errors.call_mobile ? "error" : ""}`}
              onClick={e => {
                e.stopPropagation()
                setLabelActive(3)
              }}
            >
              Broj mobitela
            </label>
            <input
              type="tel"
              onKeyPress={e => validateTelNumber(e)}
              ref={register({
                required: {
                  value: true,
                  message: "This field is required",
                },
                pattern: {
                  value: /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/i,
                  message: "Invalid mobile number",
                },
              })}
              id="call_mobile"
              name="call_mobile"
              defaultValue={offerDatas.call_mobile}
              className={`contact-mobile ${
                isLabelActive === 3 ? "active" : ""
              } ${errors.call_mobile ? "error" : ""}`}
              onClick={e => {
                e.stopPropagation()
                setLabelActive(3)
              }}
              onFocus={() => setLabelActive(3)}
              onBlur={() => {
                setLabelActive(0)
                gaInteraction()
              }}
            />
          </div>
          <div className="offer-btn-holder">
            <button type="submit" className="offer-btn">
              Sljedeće
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    offerDatas: state.strapiReducer.offerDatas,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    saveOfferDatas: bindActionCreators(saveOfferDatas, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OfferContent1)
