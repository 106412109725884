import React, { useEffect, useState } from "react"
import car from "../../../assets/car.png"
import hands from "../../../assets/hands.png"
import { Link } from "gatsby"
import "./index.scss"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getProductCategories } from "../../../redux/actions/strapiActions"
import strapi from "../../../api/strapi"
import { gaInsuranceClickEvent } from "../../../helpers/functions/googleAnalytics"

const CardItem = ({
  primaryProductCategory,
  getProductCategories,
  onClickLinkCloseBurgerBar,
}) => {
  const [windowWidth, setWindowWidth] = useState(0)

  const handleResize = () => {
    setWindowWidth(window && window.innerWidth)
  }

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)
    return function cleanup() {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseProductCategories = await strapi.get(
          "/kategorije-proizvodas"
        )
        getProductCategories(responseProductCategories.data)
      } catch (err) {
        console.log(err.message)
      }
    }
    fetchData()
  }, [getProductCategories])

  const delayAndGo = (e, to) => {
    //e.preventDefault()

    document.getElementsByClassName("navbar-holder")[0].classList.add("fadeOut")
    setTimeout(() => {
      // onClickLinkCloseBurgerBar()
      window.location.href = to
    }, 0)
  }

  return (
    <>
      {" "}
      {primaryProductCategory?.map((primary, i) => (
        <div key={primary?.id} className="content-holder">
          <img
            src={i === 0 ? car : hands}
            alt="Car"
            className="img-item"
            onLoad={e => e.target.classList.add("loaded")}
          />
          <h4>{primary?.Ime}</h4>
          {primary?.Proizvodi?.map(product => (
            <Link
              key={product?.id}
              to={`/insurance/${product?.Slug}`}
              onClick={e => {
                gaInsuranceClickEvent(`Klik na osiguranje (${product?.Slug}) sa navigacije`);
                windowWidth < 1025 && delayAndGo(e, `/insurance/${product?.Slug}`)
              }
              }
              style={{ cursor: "pointer" }}
            >
              {product?.Ime}
            </Link>
          ))}
        </div>
      ))}
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    primaryProductCategory: state.strapiReducer.primaryProductCategory,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getProductCategories: bindActionCreators(getProductCategories, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardItem)
