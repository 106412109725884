import React from "react"
import dalmatikoLogo from "../../../assets/dalmatikoLogo.png"
import { XIcon } from "../../../assets/icons"
import fuelLogo from "../../../assets/fuel-logo.png"
import { connect } from "react-redux"
import "./index.scss"

const Collab = ({ settings }) => {
  return (
    <div className="collab-holder">
      <img
        className="dalmatiko-logo"
        src={`https://api.trebamosiguranje.hr${settings?.Logo?.url}`}
        alt="Logo"
        style={{ width: "auto", height: "25px", marginRight: "10px" }}
        onLoad={e => e.target.classList.add("loaded")}
        onError={e => {
          e.target.src = dalmatikoLogo
          e.target.classList.add("loaded")
        }}
      />
      {/* <XIcon className="x-icon" />
      <img
        className="fuel-logo"
        src={fuelLogo}
        alt="Fuel logo"
        onLoad={e => e.target.classList.add("loaded")}
      /> */}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    settings: state.strapiReducer.settings,
  }
}

export default connect(mapStateToProps, null)(Collab)
